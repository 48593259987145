.admin-header {
  font-size: 45px;
}

.add-icon-div {
  position: fixed;
  bottom: 10px;
  left: 50%;
}
.add-icon-div span {
  font-size: 65px;
  /* color: #007bff; */
  color: #333;
  cursor: pointer;
}
.add-icon-div span:hover {
  color: #17a2b8 !important;
}

.link-button {
  padding: 0 !important;
  width: fit-content;
  color: #666 !important;
}

.admin-list-image-col {
  margin-bottom: 15px;
}
