.home-bio-row {
  /* padding: 5px 0 !important; */
  padding: 0 !important;
}

.home-bio-row .text-column {
  padding-left: 60px;
}

.home-bio-row .text-column p {
  text-align: left;
  font: normal normal normal 16px/30px Montserrat;
  letter-spacing: 0.32px;
  color: #dddddd;
  opacity: 1;
  line-height: 28px;
  margin-bottom: 20;
}

.home-bio-row .bio-photo-div {
  /* text-align: center; */
}

a.bio-link-text {
  color: #fff;
  font-weight: 800;
}

.home-bio-main-image-div.photo-art-bio-main-div {
  background-image: url("../../../images/bio_profile_home_desktop.jpeg");

  background-size: 115%;
  background-position: center;
  padding: 0px 0;
  height: 1100px;
}
.home-bio-main-image-div.photo-art-bio-main-div a,
.bio-text a {
  text-decoration: underline;
}

.home-bio-main-image-div.photo-art-bio-main-div .p-row {
  /* padding-top: 0; */
}
