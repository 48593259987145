/* @media (min-width: 1200px) 
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {   
  max-width: 1400px;
} */

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1400px !important;
  }
}

@media screen and (max-width: 1200px) {
  .photo-art-row.display-row {
    max-height: calc(1281 / 2216 * 1200px - 200px) !important;
  }
  .photo-art-row.categories-row {
    margin-top: 80px !important;
  }
}

@media screen and (max-width: 991px) {
  .photo-art-row.display-row {
    max-height: calc(1281 / 2216 * 992px - 200px) !important;
    min-height: calc(1281 / 2216 * 992px - 200px) !important;
  }
  .photo-art-row.categories-row {
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 767px) {
  .photo-art-row.display-row {
    max-height: calc(1281 / 2216 * 768px - 150px) !important;
    min-height: calc(1281 / 2216 * 768px - 150px) !important;
  }
  .photo-art-row.categories-row {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 575px) {
  .photo-art-row.display-row {
    max-height: calc(1281 / 2216 * 576px - 40px) !important;
    min-height: calc(1281 / 2216 * 576px - 40px) !important;
  }
  .photo-art-row.categories-row {
    margin-top: 0px !important;
  }
}
