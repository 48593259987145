.login-main-div {
  width: 460px;
  margin: 25vh auto 0 auto;
}

.login-main-div button {
  width: 100%;
}

.login-main-div .sign-in-header {
  text-align: center;
  font-size: 40px;
  margin-bottom: 20px;
}

.login-main-div .captcha .row {
  margin-left: 0;
}

.login-main-div .captcha .captcha-img {
    margin-top: -20px;
}
