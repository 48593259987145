.new-slide-show-div.not-diplay-slideshow {
  display: none;
}

#mobile-gallery-slide-show-main-div.landscape
  .fullscreen.fullscreen-enabled
  .vertical-center {
  position: relative !important;
  height: auto !important;
  /* width: 100% !important; */

  text-align: center;
}

#mobile-gallery-slide-show-main-div.landscape .fullscreen.fullscreen-enabled {
  text-align: center !important;
}

#mobile-gallery-slide-show-main-div.landscape
  .fullscreen.fullscreen-enabled
  .vertical-center.img-portrait {
  /* align-self: flex-start; */
  /* justify-self: flex-start; */
  /* object-fit: contain; */
  height: 100vh !important;
  width: auto !important;
}

#mobile-gallery-slide-show-main-div
  .fullscreen.fullscreen-enabled
  .vertical-center.img-portrait {
  /* height: 80% !important;
  width: auto !important; */
  height: auto !important;
  width: 100% !important;
  max-height: 99vh;
  position: relative !important;
}

#mobile-gallery-slide-show-main-div
  .image_protrait
  .fullscreen.fullscreen-enabled {
  text-align: center !important;
}

.badge-secondary {
  color: #999 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: solid 1px !important;
  margin-right: 5px;
  padding: 5px !important;
  font-size: 12px !important;
  margin-bottom: 5px;
  text-transform: capitalize;
  font-weight: 400 !important;
}

.badge-secondary.invisible {
  opacity: 0;
  margin-right: 0 !important;
}

#mobile-gallery-slide-show-main-div .slide-show-tags {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  width: 90%;
}

/* pre {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #eee !important;
} */

.tags-group-div {
  /* min-height: 120px; */
  /* border: solid 1px yellow; */
  position: relative;
}

.tags-group-div .tags-group-sub-div {
  /* border: solid 1px white; */
  position: absolute;
  bottom: 0;
}

.nav-items {
  overflow: auto;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.nav-items .badge {
  padding: 14px;
  color: #111;
}

.menu-container {
  display: flex;
  flex-direction: row;
  height: 30px;
  margin-left: -15px;
  margin-right: -15px;
}

.arrow-div {
  color: #999;
  margin-top: 2px;
  cursor: pointer;
  opacity: 0.5;
}
.arrow-div span.left {
  margin-left: -20px;

  background-color: rgba(0, 0, 0, 0.1);
}
.arrow-div span.right {
  margin-right: -10px;
  /* background: rgba(0, 0, 0, 0.1); */
}
