@media screen and (max-width: 1399px) {
  .home-bio-main-image-div.photo-art-bio-main-div {
    background-size: 155%;
    background-position: center;
    background-position-x: 80%;
    padding: 0px 0;
    height: 1240px;
  }
}

@media screen and (max-width: 1199px) {
  .home-bio-main-image-div.photo-art-bio-main-div {
    background-size: 170%;
    background-position: center;
    background-position-x: 75%;
    padding: 0px 0;
    height: 1125px;
  }
}
