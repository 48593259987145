.photo-art-bio-main-div {
  background-image: url("../../../images/bio-image-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 1000px;
  width: 100%;
  padding: 95px 0;
  margin-bottom: 100px;
}

.photo-art-bio-main-div p {
  text-align: left;
  font: normal normal normal 16px/30px Montserrat;
  letter-spacing: 0.32px;
  color: #dddddd;
  opacity: 1;
  padding-left: 60px;
  padding-right: 60px;
  line-height: 28px;
  margin-bottom: 20;
  text-shadow: 1px 1px 8px rgb(0 0 0 / 60%);
  color: #eee;
}
