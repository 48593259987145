@media screen and (max-width: 1399px) {
  .photo-write-row.margin-bottom-70 .first-column {
    max-width: 3% !important;
  }
  .home-outer-box .home-inner-box {
    width: 465px !important;
    height: 400px !important;
  }
  .box-title.home-page-box-title {
    width: 460px;
  }
}

@media screen and (max-width: 1199px) {
  .home-outer-box.box-500 {
    width: 400px !important;
    max-width: 400px !important;
  }

  .photo-art-column-reset {
    width: unset !important;
  }

  .home-outer-box .home-inner-box {
    width: 400px !important;
    height: 400px !important;
  }
  .box-title.home-page-box-title {
    width: 400px;
  }
  .ml-3.bio-photo-div {
    float: right;
  }
}
