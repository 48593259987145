.vertical-align-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.fullscreen.fullscreen-enabled.none-cursor {
  cursor: none !important;
}

.photo-art-col-display .fullscreen img {
  cursor: pointer;
}

.fullscreen .nav-img {
  /* color: red; */
  display: none;
}

#mobile-gallery-slide-show-main-div .fullscreen.fullscreen-enabled .nav-img {
  top: calc(50%);
  padding-top: 0;
  padding-bottom: 0;
}

.fullscreen.fullscreen-enabled .nav-img {
  /* color: white; */
  z-index: 200;
  position: absolute;
  top: calc(50% - 45vh);
  padding-top: 45vh;
  padding-bottom: 45vh;
  cursor: pointer;
  display: unset;
}

#mobile-gallery-slide-show-main-div
  .fullscreen.fullscreen-enabled
  .nav-img.left {
  left: calc(5% - 2vw);
  padding-right: 0;
  padding-left: 0;
}

#mobile-gallery-slide-show-main-div
  .fullscreen.fullscreen-enabled
  .nav-img.right {
  right: calc(5% - 2vw);
  padding-left: 0;
  padding-right: 0;
}

.fullscreen.fullscreen-enabled .nav-img.left {
  left: calc(5% - 4vw);
  padding-right: 10vw;
  padding-left: 4vw;
}

.fullscreen.fullscreen-enabled .nav-img.right {
  right: calc(5% - 4vw);
  padding-left: 10vw;
  padding-right: 4vw;
}

.fullscreen.fullscreen-enabled div.slidershow-button {
  display: none;
}

div.slidershow-button {
  z-index: 200;
  position: absolute;
  right: 5%;
  top: -82px;
}

div.slidershow-button.back-to-photo-art-index {
  left: 5%;
  right: unset;
  /* font-size: 36px; */
}
div.slidershow-button.back-to-photo-art-index .material-icons {
  font-size: 50px;
}
div.slidershow-button.back-to-photo-art-index .material-icons:hover {
  cursor: pointer;
  color: #ddd;
}

div.slidershow-button button {
  height: 55px;
  width: 162px;
  border-radius: 5px !important;
  background-color: rgba(0, 0, 0, 0);
  /* color: #222; */
  color: #fff;
  /* border: none; */
  border: solid 1px #fff;
  opacity: 0.7;
}
div.slidershow-button button span {
  vertical-align: text-top;
  font-size: 18px;
  /* font-size: 24px; */
}

div.slidershow-button button span.material-icons {
  font-size: 28px;
}

div.fullscreen-exit {
  z-index: 200;
  /* top: calc(50% + 600px); */
  right: 49%;
  position: absolute;
}

div.fullscreen-exit span,
div.fullscreen-exit img {
  font-size: 40px;
  cursor: pointer;
}

div.fullscreen-exit {
  display: none;
}

/* .fullscreen.fullscreen-enabled div.fullscreen-exit {
  display: unset;
  color: #ddd;
  opacity: 0.5;
} */

.fullscreen.fullscreen-enabled .show-icons {
  display: unset;
  /* color: #ddd; */
  /* opacity: 0.5; */
  animation: fade-in 0.8s;
}

:not(.fullscreen-enabled) .no-show-icons {
  display: none;
  opacity: 0;
}

@keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.9;
  }
}
