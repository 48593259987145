#mobile-gallery-main-div {
  margin-bottom: 50px;
}

#mobile-gallery-main-div .box-title {
  letter-spacing: 0.05em !important;
}

#mobile-gallery-main-div .gallery-item-div {
  width: 85%;
  margin: 40px auto;
}

#mobile-gallery-main-div .gallery-item-div img {
  width: 100%;
}

#mobile-gallery-main-div .gallery-item-div .gallery-item-title {
  color: #ddd;
  letter-spacing: 0.05em;
  font-size: 16px !important;
  margin-top: 8px;
}

#mobile-gallery-main-div .slideshow-link {
  width: 90%;
  /* border: solid 1px red; */
  margin: 5px auto;
  text-align: right;
  padding-right: 5px;
}
#mobile-gallery-main-div .slideshow-link a {
  font-size: 16px !important;
  color: #ddd;
  text-decoration: underline !important;
}
