.container.desc {
  /* padding-left: 40px; */
  padding: 0 10px;
}

.gallery-slide-show-category {
  font-size: 30px;
  text-transform: uppercase !important;
  color: #ddd;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
  opacity: 0.67;
}

.gallery-slide-show-title {
  font-size: 20px;

  color: #ddd;
  margin-bottom: 20px;
  /* letter-spacing: 0.1em; */
}

.gallery-slide-show-location {
  margin-bottom: 0px;
  font-size: 18px;
}

.row.gallery-slide-show-div span {
  cursor: pointer;
}

.gallery-slide-show-div {
  padding-bottom: 0px !important;
}

.gallery-row.display-row.slide-show {
  /* border: solid 1px red; */
  height: calc(100vh - 150px);
  max-height: unset;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gallery-row.display-row .fullscreen.fullscreen-enabled {
  text-align: center !important;
}

.gallery-row.display-row .fullscreen.fullscreen-enabled img.vertical-center {
  margin: unset;
  position: unset;
  top: unset;
  -ms-transform: unset;
  transform: unset;
}

.left-desc-div {
  /* height: 600px; */
  /* border: solid 1px yellow; */
  width: 90%;
}

.gallery-slide-show-category {
  height: 50px;
  /* border: solid 1px white; */
}

.gallery-slide-show-title {
  /* height: 60px; */
  /* min-height: 50px; */
  /* border: solid 1px white; */
}

.gallery-slide-show-content-and-location-div {
  height: 310px;
}

.gallery-slide-show-content {
  /* height: 350px; */
  /* min-height: 300px; */
  /* border: solid 1px white; */
  overflow-y: auto;
  max-height: 265px;
  /* background-color: blue; */
  margin-bottom: 25px;
}

.row.gallery-slide-show-div span.material-icons {
  font-size: 40px !important;
}

.img-container {
  /* border: solid 1px yellow; */
  padding-right: 0;
  padding-left: 0 !important;
}

/* padding-left: calc((100% - 731px)/2); */
/* left: calc((100% - 731px)/2); */

.gallery-row.display-row.slide-show img {
  left: calc((100% - var(--mg_left_offset)) / 2);
  max-height: calc(100vh - 350px);
}

.gallery-row.display-row.slide-show .fullscreen.fullscreen-enabled img {
  max-height: calc(100vh - 100px);
}

.gallery-row.display-row.slide-show .fullscreen.fullscreen-enabled img.odd {
  animation: fadeIn-full-img 1.5s;
}

.gallery-row.display-row.slide-show .fullscreen.fullscreen-enabled img.even {
  animation: fadeIn-full-img-even 1.5s;
}

@keyframes fadeIn-full-img {
  0% {
    opacity: 0;
    /* transform: scale(1); */
  }
  100% {
    opacity: 1;
    /* transform: scale(1); */
  }
}

@keyframes fadeIn-full-img-even {
  0% {
    opacity: 0;
    /* transform: scale(0.8); */
  }
  100% {
    opacity: 1;
    /* transform: scale(1); */
  }
}