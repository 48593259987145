/* .bashboard-container {
  min-height: 100vh;
} */

.dashboard-main-row {
  min-height: 100vh;
}

.left-panel {
  background-color: #f8f8f8;
  max-width: 280px !important;
}

.dash-board-menu {
  /* margin-top: 10vh; */
  margin-left: 45px;
  line-height: 50px;

  position: fixed;
  top: 10vh;
}

.right-panel {
  background-color: #fcfcfc;
  padding-top: 8vh;
  padding-left: 30px !important;
  padding-bottom: 100px;
}

.dash-board-menu .menu-collapse {
  background-color: #fcfcfc;
  border: solid 1px #ddd;
  padding: 10px 20px;
}
.dash-board-menu .collapse-main-section {
  cursor: pointer;
  font-size: 17px;
}

.dash-board-menu .material-icons {
  vertical-align: middle;
  font-size: 24px;
}
