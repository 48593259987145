.home-container.writing-section.reading {
  min-height: calc(100vh - 400px);
}

.home-container.writing-section.reading .writing-title {
  text-align: center;
  letter-spacing: 6.99px;
  color: #dddddd;
  text-transform: uppercase;
  opacity: 0.67;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
}
.home-container.writing-section.reading .control-icon-row {
  padding: 5px 0;
}

.home-container.writing-section.reading .control-icon-row span.material-icons {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 35px;
  font-weight: 600;
}
.home-container.writing-section.reading
  .control-icon-row
  span.material-icons:hover {
  cursor: pointer;
}

.home-container.writing-section.reading .control-icon-row .control-icon-col {
  text-align: center;
}

.home-container.writing-section.reading hr {
  width: 22%;
  border-top: 1px solid #333;
  margin: 50px auto;
}

.home-container.writing-section.reading .writing-body {
  letter-spacing: 0.32px;
  color: #dddddd;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  padding: 10px 100px;
}

.home-container.writing-section.reading.mobile {
  margin-top: 100px;
}

.home-container.writing-section.reading.mobile hr {
  width: 80%;
}

.scroll-to-top span.material-icons {
  font-size: 60px !important;
  /* color: #eee; */
  color: rgba(255, 255, 255, 0.5) !important;
  transform: rotate(180deg);
  position: fixed;
  right: 10px;
  bottom: 18%;
  z-index: 9999;
}

.home-container.writing-section.reading h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.home-container.writing-section.reading h6 {
  font-size: 12px;
  margin-bottom: 20px;
}

.home-container.writing-section.reading p {
  margin-top: 10px;
}

.writing-title.writing {
  font-weight: 600;
}
.writing-title.writing,
.control-icon-col {
  text-align: left !important;
  padding-left: 100px !important;
}
.hr-writing-tag hr {
  margin-left: 90px !important;
}

.writing-subtitle {
  color: #dddddd;
  opacity: 0.67;
  text-align: left !important;
  padding-left: 100px !important;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  
  letter-spacing: 0.32px;
}
.writing-subtitle h4{
  font-weight: 600 !important;
}

.markdownimage {
  border-radius: 10px;
  box-shadow: 5px 10px 22px #8e8e8e33;
}

@media (max-width: 999px) {
  .writing-subtitle,
  .writing-title.writing,
  .control-icon-col {
    text-align: center !important;
    padding-left: 0 !important;
  }
}

@media (max-width: 420px) {
  .markdownimage {
    width: 100% !important;
  }

}
