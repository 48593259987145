/* .home-container.photo-art {
  min-height: unset;
} */
.home-container.normal {
  min-height: unset;
}

.photo-art-display {
  background: #606060 !important;
  /* background-color: #919191; */
  max-width: unset !important;
}

.photo-art-row {
  min-height: 400px;
}

.photo-art-row.display-row {
  background-color: #171717;
  margin-top: -40px;
  height: calc(1281 / 2216 * 100vw - 100px);
  max-height: calc(1281 / 2216 * 1400px - 100px);
  /* border: solid 1px yellow; */
}

.photo-art-row.categories-row {
  margin-top: 130px;
}

.photo-art-col-display img {
  max-width: 100%;
  max-height: 100%;
}

.photo-art-main-image {
  height: 750px;
  background-size: cover;
  background-position: center center;
}

.react-multi-carousel-track div {
  /* height: 358px; */
  /* border: solid 1px #fff; */
  margin-right: 10px;
  margin-left: 1px;
}
.react-multi-carousel-list button {
  width: unset !important;
  background: rgba(0, 0, 0, 0.1);
}

.react-multi-carousel-list button:hover {
  background: rgba(0, 0, 0, 0.5);
}
.react-multiple-carousel__arrow--left {
  left: calc(2% - 2px);
}
.react-multiple-carousel__arrow--right {
  right: calc(2% + 1px);
}

.category-list-image-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.galleries-list,
.galleries-title {
  padding: 0 80px !important;
}

.photo-art-row.categories-row .galleries-title {
  color: #ddd;
  font-size: 28px;
  margin-top: -40px;
  margin-bottom: 0;
}

.galleries-list .category-title {
  height: unset;
  text-transform: uppercase;
  padding: 10px;
  padding-left: 1px;
  text-align: left;
}
.category-title {
  text-align: center;
}

.galleries-list-row.row {
  padding-top: 0 !important;
}

.photo-art-inner-box {
  /* background-color: #ddd; */
  position: relative;
  width: 100%;
  padding-top: 120%;
  /* 1:1 Aspect Ratio */
}

.photo-art-small-text-div {
  position: absolute;
  top: 65%;
  left: calc(50% - 200px);
  font-size: 50px;
  letter-spacing: 0.5em;
  opacity: 1;
  font-weight: 200;
  z-index: 10;
}
.photo-art-large-text-div {
  position: absolute;
  width: calc(100% + 260px);
  top: 45%;
  left: -135px;
  font-size: 275px;
  letter-spacing: 0.2em;
  opacity: 0.08;
  font-weight: 200;
  z-index: 5;
  /* word-wrap: break-word; */
  /* text-overflow: ""; */
  white-space: nowrap;
  overflow: hidden;
}
