.row.form-group {
  margin-bottom: 25px;
}

.row.form-group textarea {
  min-height: 55vh;
}

.upload-image-div img {
  margin: 5px 5px 5px 0;
}
.upload-image-div {
  margin-top: 5px;
  background-color: #f5f5f5;
  /* width: fit-content; */
}

.upload-image-div-item {
  display: inline-block;
  position: relative;
}

.upload-image-div-item-number {
  position: absolute;
  bottom: 5px !important;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 20px;
  left: 5px;
  padding: 5px 20px;
}

.copy-to-clipboard,
.delete-selected-icon-div {
  position: absolute;
  top: 10px;
  /* left: 5px; */
  cursor: pointer;
  padding: 5px 10px;
  width: fit-content;
}
.copy-to-clipboard {
  left: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.delete-selected-icon-div {
  right: 10px;
  /* background-color: rgba(255, 255, 255, 0.8);
    color: #000; */
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.file-drop-target {
  width: 300px;
  border: 1px solid #ddd;
  padding: 30px;
  text-align: center;
}

.file-drop-dragging-over-target.file-drop-target {
  background-color: #198754;
}
