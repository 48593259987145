@media screen and (max-width: 1199px) {
  .img-container.desktop-slide-show-container {
    margin-top: 150px;
  }
  .gallery-row.display-row.slide-show img {
    /* max-height: calc(100vh - 550px) !important; */
    max-height: 50vh !important;
  }

  .gallery-slide-show-content-and-location-div {
    height: 235px !important;
  }

  .gallery-row.display-row.slide-show .fullscreen-enabled img {
    max-height: calc(100vh - 350px) !important;
  }
}
