a,
a:hover {
  color: #666 !important;
}

*:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

body {
    font-size: 15px;
}