#mobile-photo-art-main-div.writings-section .book-sub-image {
  position: absolute;
  top: calc(50% - 50px);
  width: 100px !important;

  width: fit-content;
  right: 0;
  left: 0;
  margin: auto;
}

#mobile-photo-art-main-div.writings-section .box-title.sub-title {
  font-size: 20px;
  margin-top: -20px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 50px;
}

#mobile-photo-art-main-div.writings-section
  .material-icons.go-back-to-category-icon {
  position: absolute;
  left: 5%;
  font-size: 90px;
}


.photo-art-display {
  background: #626060 !important;

}
.thumbnail-img {
  border-radius: 6px;
  box-shadow: 5px 10px 22px #8e8e8eee;
}

@media (max-width: 999px) {
  .writing-body, 
  .writing-date, 
  .writing-title {
    text-align: left;
  }
  .writing-title {
    font-size: 20px;
  }
  .writing-date {
    font-size: 12px;
  }
  .writings-list-row.row {
    margin: 10px 30px !important;
  }

}

.mobile-article-list-div {
  border-radius: 6px;
  box-shadow: 5px 10px 22px #8e8e8eee;
  background: #eee;
  width: 90%;
  margin: 20px auto;
  padding: 10px 0;
  
}

.mobile-article-list-div a{
  color: #111 !important;
}
