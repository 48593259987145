#mobile-photo-art-main-div .photo-art-main-image {
  height: 30vh !important;
  background-position: center center;
}

#mobile-photo-art-main-div .galleries-section {
  background: #606060;
  /* margin-top: 30px; */
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
}

#mobile-photo-art-main-div .category-title {
  text-transform: uppercase;
  text-align: left;
  width: 90%;
  margin: auto;
  color: #ccc;
}

#mobile-photo-art-main-div .galleries-img {
  width: 90%;
  margin: 35px auto 10px auto;
}

#mobile-photo-art-main-div .bio-img-background {
  width: 100%;
  padding-bottom: 20px;
}
