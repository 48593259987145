.gallery-row.display-row,
.gallery-row.display-row img {
  max-height: calc(100vh - 240px);
  max-width: 100%;
  padding-top: 0 !important;
}

.gallery-row.display-row img {
  width: auto;
}

.gallery-row.display-row .fullscreen {
  /* text-align: center; */
}

.center {
  text-align: center;
}
.gallery-header {
  text-transform: uppercase;
  font-size: 30px;
  color: #ddd;
  letter-spacing: 0.5em;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

.row.gallery-list-div {
  /* margin-top: 60px !important; */
  margin-top: 0px !important;
  justify-content: center;
}
.row.gallery-list-div .col-xs-3 {
  margin: 30px 20px;
  /* margin-right: 30px;
  margin-bottom: 80px; */
}

.row.gallery-list-div .photo-art-inner-box {
  width: 300px !important;
}

/* .row.gallery-list-div {

} */

/* Image Grid */
.gallery-image-grid .row-1 {
  display: flex;
  flex-wrap: wrap;
  padding: 0 5px;
}

/* Create two equal columns that sits next to each other */
.gallery-image-grid .column-1 {
  flex: 25%;
  max-width: 25%;
  padding: 0 8px;
}

.gallery-image-grid .column-1 img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  /* width: 60px; */
}

.gallery-image-grid .column-1 .image-div {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  /* border: solid 1px #222; */
  position: relative;
}
.gallery-image-grid .column-1 .image-div .bottom-right {
  position: absolute;
  bottom: 8px;
  left: 16px;
  background-color: rgba(22, 22, 22, 0.3);
  opacity: 0.8;
  padding: 2px 10px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.gallery-image-grid .column-1 .image-div:hover .bottom-right {
  background-color: rgba(22, 22, 22, 0.8);
  opacity: 0.9;
}

.react-photo-gallery--gallery .image-div {
  /* margin-top: 8px;
  vertical-align: middle;
  width: 100%; */
  /* border: solid 1px #222; */
  position: relative;
  /* padding: 0px; */
}
.react-photo-gallery--gallery .image-div .bottom-right {
  position: absolute;
  bottom: 8px;
  left: 16px;
  background-color: rgba(22, 22, 22, 0.3);
  opacity: 0.8;
  padding: 2px 10px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.react-photo-gallery--gallery .image-div:hover .bottom-right {
  background-color: rgba(22, 22, 22, 0.8);
  opacity: 0.9;
  cursor: pointer;
}

.not-display-gallery {
  display: none;
}
