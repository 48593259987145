.menu-links {
  margin-top: 10px;
}

.menu-links a {
  /* text-decoration: underline;
    border-bottom-width: 1px;
    padding-top: 10px; */
  text-decoration: none;
  /* border-bottom: 1px solid;
  padding-bottom: 1px; */
}

.home-body .menu-links a.active {
  color: #fff !important;
  /* border-bottom: 1px solid;
  padding-bottom: 1px; */
}
.home-body .menu-links a:hover {
  color: #bc8c09 !important;
  cursor: pointer;
}

.form-group.has-search {
  margin-top: 10px !important;
}

.material-icons.form-control-feedback.right-search {
  top: 2px;
}

.menu select {
  width: 300px !important;
  display: inline !important;
  border-radius: 0 !important;
  /* margin-left: 35px !important; */
  float: right;

  background: #171717 !important;
  color: #fff !important;
  border: solid 1px #707070 !important;
  /* border-bottom: solid 1px #707070 !important; */
}

.menu .form-group.category-select {
  margin-top: 5px;
}

.menu .select-div {
  height: 40px;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 3px;
  margin-bottom: 1em;
  display: contents;
}

.menu .select-div:after {
  content: "▼";
  padding: 12px 18px;
  position: absolute;
  right: 20px;
  top: 0;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  pointer-events: none;
  color: #ddd;
}

.menu .select__field {
  height: 40px;
  width: 100%;
  padding: 5px 15px;
  color: #616263;
  background-color: #ececec;
  border: 1px solid #e3e3e3;
  outline: none;
  font-size: 16px;

  appearance: none;
  /* for modern browsers */
  -moz-appearance: none;
  /* for firefox */
  -webkit-appearance: none;
  /* for webkit browsers */
}

/* remove default caret for ie */

.menu .select__field::-ms-expand {
  display: none;
}

/* .select__field:focus:invalid {
  border-color: #FD6347;
}

.select__field:required:valid {
  border-color: #006400;
} */

.menu .material-icons.home-icon {
  vertical-align: sub;
}
