#mobile-gallery-slide-show-main-div .fullscreen {
  text-align: center;
}

#mobile-gallery-slide-show-main-div .vertical-center {
  position: relative;
  transform: unset !important;
  width: 90%;
}

/* tate care of fullscreen enable */
#mobile-gallery-slide-show-main-div
  .fullscreen.fullscreen-enabled
  .vertical-center {
  margin: 0;
  position: absolute !important;
  top: 50%;
  width: 100%;
  transform: translateY(-50%) !important;
}

#mobile-gallery-slide-show-main-div .fullscreen.fullscreen-enabled {
  text-align: left;
}
#mobile-gallery-slide-show-main-div.landscape .fullscreen.fullscreen-enabled img {
  max-height: 99vh;
  width: auto !important;
}
/* tate care of fullscreen enable */

#mobile-gallery-slide-show-main-div .gallery-slide-show-title,
#mobile-gallery-slide-show-main-div .gallery-slide-show-body {
  width: 90%;
  margin: 80px auto 20px auto;
  color: #eee;
  font-size: 24px;
}
#mobile-gallery-slide-show-main-div .gallery-slide-show-body {
  font-size: 16px;
  color: #ddd;
  margin-top: 20px;
}

body.home-body-without-minheight .home-body {
  min-height: unset;
}

#mobile-gallery-slide-show-main-div .gallery-slide-show-controller {
  width: 100%;
  /* border: solid 1px white; */
  margin: 0 auto;
  padding: 25px 0 20px 0;
  position: fixed;
  bottom: 0;
  text-align: center;
  background-color: rgba(23, 23, 23, 0.6);
  z-index: 999;
}
#mobile-gallery-slide-show-main-div .gallery-slide-show-controller div.inline {
  float: left;
  width: 25%;
  text-align: center;
}

#mobile-gallery-slide-show-main-div
  .gallery-slide-show-controller
  div.inline
  span {
  font-size: 32px;
  cursor: pointer;
}
