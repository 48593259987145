.sort-image-main-div .image-div .bottom-right {
  color: #eee !important;
  font-size: 14px;
}

.admin-reorder-modal.modal-dialog {
  margin-top: 40vh;
  max-width: 600px;
}

.admin-reorder-modal.modal-dialog .modal-body {
  font-weight: 700;
  font-size: 20px;
}

/* @media (min-width: 576px) {
  .admin-sort.modal-dialog {
    max-width: 100% !important;
    margin: 0;
  }
} */

.admin-sort.modal-dialog .modal-content {
  min-height: 100vh;
}

.admin-sort.modal-dialog .modal-header {
  width: fit-content;
  margin: 30px auto auto auto;
}
.admin-sort.modal-dialog .modal-header h5 {
  font-weight: 600;
}

/* .sort-image-main-div {

} */

.sort-image-main-div .sort-image-title-div {
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.sort-image-main-div .sort-image-title-div span {
  font-size: 40px;
  float: left;
  padding-left: 20px;
  cursor: pointer;
}

.sort-image-main-div img {
  cursor: pointer;
}
/* .sort-image-main-div select.form-control {
  margin: auto;
  width: 99%;
} */

.sorted-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0 auto !important;
  width: 100%;
  height: 90px; /* Height of the footer */
  background: rgba(200, 200, 200, 0.2);
  /* top: calc(100vh - 80px); */
  text-align: center;
  padding-top: 20px;
  margin-left: 5px;
}
.sorted-footer button {
  height: 50px;
  margin: 0 100px;
}
