@media screen and (max-width: 1649px) {
  .photo-art-main-image {
    /* height: calc(1320 / 100vw * 700) !important; */
  }
  .photo-art-large-text-div {
    width: calc(100% + 130px);
  }
}
@media screen and (max-width: 1399px) {
  .photo-art-bio-main-div.container p {
    padding-left: 30px;
    padding-right: 30px;
  }
  .photo-art-bio-main-div.container {
    /* padding-top: 30px; */
  }

  .home-container.normal.photo-art-bio,
  .home-container.normal.photo-art-bio .photo-art-bio-main-div.container {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 1320px) {
  .photo-art-main-image {
    height: calc(100vw / 1320 * 700) !important;
  }
  .photo-art-bio-main-div.container {
    padding-top: 30px;
  }
}

@media screen and (max-width: 1199px) {
  .photo-art-main-image {
    height: 500px !important;
  }
  .photo-art-large-text-div {
    font-size: 150px !important;
    text-align: center !important;
    top: 60%;
    left: -66px;
    width: 95vw;
  }
  .home-container.photo-art.photo-art-display.container {
    min-height: 80vh;
  }
  .photo-art-bio-main-div.container {
    padding-top: 180px;
  }
}

/* mobile session */
@media screen and (min-width: 640px) {
  #mobile-photo-art-main-div .photo-art-main-image {
    height: 38vh !important;
  }
}
