.margin-top-mobileview {
  margin-top: 120px;
}

.same-edge-container {
  background-color: silver;
  /* display: inline-block; */
  height: 0;
  padding-bottom: 80%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #353535;
}

.same-edge-container.writing-image {
  background-image: url("../../../../images/writings.jpeg");
  padding-bottom: 70%;
}

#mobile-home-main-div .bio-text {
  padding: 0;
}
#mobile-home-main-div .bio-text p,
#mobile-photo-art-main-div .bio-text p {
  padding: 10px 20px;
  letter-spacing: 0.05em;
  color: #eee;
  font-size: 16px;
}

#mobile-home-main-div .bio-text img {
  width: 100%;
}

#mobile-home-main-div .scroll-to-top,
#mobile-photo-art-main-div .scroll-to-top,
#mobile-gallery-main-div .scroll-to-top {
  position: fixed;
  right: 10px;
  bottom: 18%;
  z-index: 9999;
}
#mobile-home-main-div .scroll-to-top span.material-icons,
#mobile-photo-art-main-div .scroll-to-top span.material-icons,
#mobile-gallery-main-div .scroll-to-top span.material-icons {
  font-size: 60px;
  color: #eee;
  color: rgba(255, 255, 255, 0.5);
  transform: rotate(180deg);
}
