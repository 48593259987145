@media screen and (max-width: 1649px) {
  .writing-section-first-div .writings-text-div .main-title {
    /* margin-top: min(calc(1281 / 2216 * 100vw - 730px), 300px); */
  }

  .photo-art-main-image.writing-section {
    height: calc(100% + 150px) !important;
  }
  .writing-section-first-div .writings-text-div .main-title {
    margin-top: 130px;
  }
}
@media screen and (max-width: 1399px) {
  .photo-art-row.categories-row.writing-section {
    margin-top: 0px;
  }

  .writing-section-first-div img {
    width: 550px;
    height: auto;
  }

  .writing-section-first-div img.book-sub-image {
    width: 120px;
    left: calc(550px / 2 - 60px);
    height: -webkit-fill-available;
  }

  .photo-art-main-image.writing-section {
    height: calc(100% + 150px);
  }

  .writing-section-first-div .writings-text-div .main-title {
    margin-top: 130px;
  }
}

@media screen and (max-width: 1320px) {
  .photo-art-main-image.writing-section {
    height: 130% !important;
  }
}

@media screen and (max-width: 1199px) {
  .photo-art-row.categories-row.writing-section {
    margin-top: 0px !important;
  }
  .writing-section-first-div img {
    width: 450px;
    height: auto;
  }
  .writing-section-first-div img.book-sub-image {
    width: 100px;
    left: calc(450px / 2 - 50px);
    height: -webkit-fill-available;
  }
}

/* mobile session */
@media screen and (max-width: 640px) {
  #mobile-photo-art-main-div.writings-section .writings-list-row {
    margin: 10px 30px !important;
    text-align: center;
  }
  #mobile-photo-art-main-div.writings-section
    .photo-art-main-image
    .main-title {
    font-size: 40px !important;
  }
}

@media screen and (max-width: 999px) {
  .home-container.writing-section.reading .writing-body {
    padding: 10px 30px !important;
  }
}