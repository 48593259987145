/* .my-container {
    columns: 3 auto;
   
}

.aa {
    width: 300px;

    border: solid 1px ;
    padding: 15px;
} */

.admin-images-list-main {
  color: #666;
}

#admin-list-images-row .admin-list-image-col {
  text-align: left;
  margin: 15px auto;
  text-transform: capitalize;
}

#admin-list-images-row .admin-list-image-col.check {
  text-align: right;
}

#admin-list-images-row .admin-list-image-col.col-header {
  white-space: nowrap;
  font-weight: unset;
}

#admin-list-images-row .no-wrop {
  white-space: nowrap;
  /* padding-left: 3px; */
}

.admin-header {
  font-size: 45px;
  margin-left: 10px;
}
.col-header {
  font-weight: 600;
}

.link-button {
  padding: 0 !important;
  width: fit-content;
  color: #666 !important;
}

.form-check input[type="checkbox"] {
  visibility: hidden;
  margin: 10px 10px 130px -8;
  border: solid 1px;
}

.form-check input[type="checkbox"] + label:before {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 18px;
  margin: 0 10px 0 0;
  padding: 0;
  vertical-align: top;
  width: 18px;
  background: #fff;
}

.form-check input[type="checkbox"] + label:before,
.form-check label {
  cursor: pointer;
}

.form-check input[type="checkbox"]:checked + label:before {
  /* background: #75dab4; */
  background: #bbb;
  color: #fff;
  content: "\2713";
  text-align: center;
  font-size: 22px;
  /*    padding-top: 1px;*/
}
.form-check select,
.form-check select:focus {
  text-indent: 0;
}

.form-check label {
  font-size: 12px;
}

/* input:disabled {
  cursor: not-allowed;
} */

input[type="checkbox"]:disabled + label,
input[type="checkbox"]:disabled,
.form-check input[type="checkbox"]:disabled + label:before {
  cursor: not-allowed !important;
}

#admin-list-images-row .admin-list-image-col img:hover {
  cursor: pointer;
}

.admin-images-list-main .admin-header span.total-detail {
  font-size: 16px;
  padding-left: 20px;
  vertical-align: middle;
}

.admin-list-image-col.col-header span.material-icons {
  vertical-align: middle;
  cursor: pointer;
}
